import { APPLE_APP_STORE_LINK, GOOGLE_PLAY_STORE_LINK } from '@/controllers/app/app.constants';

export class NativeAppHelpers {
  static getAndroidLink(source: string): string {
    return `${GOOGLE_PLAY_STORE_LINK}&referrer=utm_source%3Dapp_promo%26utm_medium%3D${source}_button%26utm_campaign%3Ddownload_app%26anid%3Dadmob`;
  }

  static getIOSLink(source: string): string {
    return `${APPLE_APP_STORE_LINK}?utm_source=app_promo&utm_medium=${source}_button&utm_campaign=download_app`;
  }
}
