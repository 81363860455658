import React, { FC } from 'react';
import { NativeAppEvents } from '../nativeApp.events';
import { nativeAppGlobalStyles } from '../nativeApp.globalStyles';
import { GooglePlayIcon } from './components/GooglePlayIcon';
import { NativeAppHelpers } from '../nativeApp.helpers';

interface Props {
  source: string;
  dataQa?: string;
}

export const GooglePlayButton: FC<Props> = (props) => {
  const {
    source,
    dataQa,
    children,
  } = props;

  const href = NativeAppHelpers.getAndroidLink(source);

  return (
    <a
      onClick={() => NativeAppEvents.sendEvent(
        NativeAppEvents.events.appStoreButtonClicked,
        {
          source,
          provider: 'google',
        },
      )}
      href={href}
      rel="noopener noreferrer"
      target="_blank"
      className={nativeAppGlobalStyles.storeButton}
      data-qa={dataQa}
    >
      {children || (
        <GooglePlayIcon className={nativeAppGlobalStyles.storeIcon} />
      )}
    </a>
  );
};
